import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Categories = ({ icon, category }) => {
  const { t } = useTranslation();

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  return (
    <motion.div
      variants={item}
      whileHover={{ scale: 1.1 }}
      className="flex items-center flex-col gap-4 bg-white p-8 rounded-md"
    >
      <div className="text-4xl text-Teal">{icon}</div>
      <div>{t(category)}</div>
      {/* <button className="text-sm text-gray">View More</button> */}
    </motion.div>
  );
};

export default Categories;
