import React from "react";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ height: 0 }}
      whileInView={{ height: "auto" }}
      transition={{ duration: 1 }}
      className="bg-Teal p-10"
    >
      <div className="grid md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-1 place-items-start gap-8 text-white">
        <div>
          <div className="font-bold mb-6">{t("contact")}</div>
          <div className="text-sm mb-4">info@ksa.smartschool.sd</div>
          <div className="mb-4">
            <div className="text-sm mb-1">
              مدينة العبور - الحي الثاني - صينية الروضة بجوار السنترال
            </div>
            <div className="text-sm">201554458892+</div>
          </div>
          <div className="mb-4">
            <div className="text-sm mb-1">
              الجيزة - فيصل العشرين - شارع جمال عبد الناصر خلف الاسعاف
            </div>
            <div className="text-sm">201151908579+</div>
          </div>
          <div className="mb-4">
            <div className="text-sm mb-1">
              مصر الجديدة - سراي القبة بجوار محطة الميترو - شارع الكريم
            </div>
            <div className="text-sm">201116337320+</div>
          </div>
        </div>

        <div>
          <div className="font-bold mb-6">Follow us</div>
          <div className="flex gap-4 mt-4">
            <a
              href="https://www.facebook.com/people/%D8%A3%D9%83%D8%A7%D8%AF%D9%8A%D9%85%D9%8A%D8%A9-%D9%85%D9%81%D8%AA%D8%A7%D8%AD-%D8%A7%D9%84%D8%B9%D9%84%D9%88%D9%85/100083871752520/?mibextid=ZbWKwL"
              target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsFacebook />
            </a>
            <a
              href="#"
              // target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsInstagram />
            </a>
            <a
              href="#"
              // target={"_blank"}
              rel="noreferrer"
              className="hover:scale-110 text-xl"
            >
              <BsTwitter />
            </a>
          </div>
        </div>
        <div>
          <div className="font mb-6">
            <a href="http://www.keeti.sd" target={"_blank"} rel="noreferrer">
              Powered By Keeti IT
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;
