import React from "react";

import { About, Journey, Courses } from "./index";
import AnimatedPage from "./AnimatedPage";
import Hero from "./Hero-s";

const HomePage = () => {
  return (
    <AnimatedPage>
      <Hero />
      {/* <Home /> */}
      <About />

      <Courses />
      <Journey />
      {/* <Contact /> */}
    </AnimatedPage>
  );
};

export default HomePage;
