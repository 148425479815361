import React from "react";
// import teacher1 from "../../assets/teacher1.png";//map-1024x496
// import teacher2 from "../../assets/teacher2.png";

import map from "../../assets/map-1024x496.png";
import services from "../../assets/services.jpg";
import { accordions } from "../../Data";
import Accordion from "./Accordion";
import { useTranslation } from "react-i18next";
const Teacher = () => {
  const { t } = useTranslation();
  return (
    <div className="section" id="journey">
      <div className="grid sm:grid-cols-2 place-items-center gap-8">
        <div className="pl-5">
          <div className="font-bold sm:text-[1.875rem] text-[1.5rem] mb-5">
            {t("our")} <span className="text-red-700">{t("vision")}</span>
          </div>
          <p className="text-sm leading-7 text-gray mb-5">
            {t("vision_summary")}
          </p>
        </div>
        <div className="p-4 md:w-3/4 sm:row-start-1">
          <img src={map} alt="" />
        </div>
        <div className="pl-5">
          <div className="font-bold sm:text-[1.875rem] text-[1.5rem] mb-5">
            {t("our")} <span className="text-Teal">{t("services")}</span>
          </div>
          <p className="text-sm leading-7 text-gray mb-5">
            {t("services_summary")}
          </p>
        </div>
        <div className="p-4 md:w-3/4">
          <img src={services} alt="" className="rounded-md" />
        </div>
      </div>
      <div className="text-center my-8 font-bold sm:text-[1.875rem] text-[1.5rem]">
        {t("q_a_title1")}{" "}
        <span className="text-red-700">{t("q_a_title2")}</span>
      </div>
      <div className="mt-12 max-w-[700px] mx-auto">
        {accordions.map((accordion) => {
          return <Accordion key={accordion.id} {...accordion} />;
        })}
      </div>
    </div>
  );
};

export default Teacher;
