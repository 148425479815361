import axios from "axios";

export const BASE_URL = "https://ksaapi.smartschool.sd";
//export const BASE_URL = "http://localhost:5117";
//export const BASE_URL = "https://localhost:7153";
//export const BASE_URL = "http://194.195.87.30:93";
const API_ENDPOINT = BASE_URL + "/api/";

export const authApi = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
});

authApi.defaults.headers.common["Content-Type"] = "application/json";
