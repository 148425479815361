import "./globals";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ReactQueryWrapper from "./ReactQueryWrapper";
import "./i18n";

const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>جاري التحميل...</h3>
  </div>
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Suspense fallback={loadingMarkup}>
    <BrowserRouter>
      {/* <Routes>
        <Route
          path="/*"
          element={ */}
      <ReactQueryWrapper>
        {/* <I18nextProvider i18n={i18next}> */}
        <App />
        {/* </I18nextProvider> */}
      </ReactQueryWrapper>
      {/* }
        />
      </Routes> */}
    </BrowserRouter>
  </Suspense>
  // </React.StrictMode>
);
