import React from "react";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="section pt-[6rem]" id="about">
      <div className="grid place-items-center">
        <p className="text-sm text-gray leading-7 mb-4">{t("about_us")}</p>
      </div>
    </div>
  );
};

export default About;
