import { FiPenTool } from "react-icons/fi";
import { FaLaptopCode } from "react-icons/fa";
import { BsBarChartLine } from "react-icons/bs";
import { MdOutlineScience } from "react-icons/md";

import courses1 from "./assets/courses1.jpg";
import courses2 from "./assets/courses2.jpg";
import courses3 from "./assets/courses3.jpg";
import courses4 from "./assets/courses4.jpg";
import courses5 from "./assets/courses5.jpg";
import courses6 from "./assets/courses6.jpg";
import courses7 from "./assets/courses7.jpg";
import courses8 from "./assets/courses8.jpg";

import logo1 from "./assets/logo1.png";
import logo2 from "./assets/logo2.png";
import logo3 from "./assets/logo3.png";
import logo4 from "./assets/logo4.png";
import logo5 from "./assets/logo5.png";
import logo6 from "./assets/logo6.png";

export const navLinks = [
  {
    id: 1,
    href: "/#home",
    link: "home",
  },
  {
    id: 2,
    href: "/#about",
    link: "about",
  },
  {
    id: 3,
    href: "/#benefits",
    link: "benefits",
  },
  {
    id: 4,
    href: "/#journey",
    link: "journey",
  },
  // {
  //   id: 5,
  //   href: "/#contact",
  //   link: "contact",
  // },
  // {
  //   id: 6,
  //   href: "/#events",
  //   link: "events",
  // },
  // {
  //   id: 7,
  //   href: "/#locations",
  //   link: "locations",
  // },
  // {
  //   id: 8,
  //   href: "/#calendar",
  //   link: "calendar",
  // },
];

export const categories = [
  {
    id: 1,
    icon: <FiPenTool />,
    category: "cat_1",
  },
  {
    id: 2,
    icon: <FaLaptopCode />,
    category: "cat_2",
  },
  {
    id: 3,
    icon: <BsBarChartLine />,
    category: "cat_3",
  },
  {
    id: 4,
    icon: <MdOutlineScience />,
    category: "cat_4",
  },
];

export const courses = [
  {
    id: 1,
    image: courses1,
    category: "event",
    title: "news_title_1",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 2,
    image: courses2,
    category: "news",
    title: "news_title_2",
    rating: 4.8,
    participants: 700,
    price: 125,
  },
  {
    id: 3,
    image: courses3,
    category: "news",
    title: "news_title_3",
    rating: 4.9,
    participants: 300,
    price: 99,
  },
  {
    id: 4,
    image: courses4,
    category: "news",
    title: "news_title_4",
    rating: 4.7,
    participants: 600,
    price: 85,
  },
  {
    id: 5,
    image: courses5,
    category: "event",
    title: "news_title_5",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 6,
    image: courses6,
    category: "event",
    title: "news_title_6",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 7,
    image: courses7,
    category: "news",
    title: "news_title_7",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
  {
    id: 8,
    image: courses8,
    category: "event",
    title: "news_title_8",
    rating: 4.9,
    participants: 500,
    price: 105,
  },
];

export const logos = [logo1, logo2, logo3, logo4, logo5, logo6];

export const accordions = [
  {
    id: 1,
    title: "q1",
    answer: "answer1",
  },
  {
    id: 2,
    title: "q2",
    answer: "answer2",
  },
  {
    id: 3,
    title: "q3",
    answer: "answer3",
  },
  {
    id: 4,
    title: "q4",
    answer: "answer4",
  },
];
