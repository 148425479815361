import React from "react";
import StdInfo from "./StdInfo";
import StudentInfo from "./StudentInfo";

const ManageStd = () => {
  return (
    <>
      <StdInfo />
      <StudentInfo />
    </>
  );
};

export default ManageStd;
