import React from "react";
import { default as useFormContext } from "../../hooks/useUpdateFormContext";
import { PhoneInput } from "../ui/phone-input";
import { useTranslation } from "react-i18next";
const EmergencyInfo = () => {
  const { t } = useTranslation();
  const { data, handleChange, handleCustomElementChange } = useFormContext();

  const content = (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col w-full">
        <label htmlFor="e_relation">
          {t("relationship")} <span className="text-red-600">*</span>
        </label>
        <select
          id="e_relation"
          className="form-textbox"
          name="e_relation"
          value={data.e_relation}
          onChange={handleChange}
        >
          <option value="">{t("select")}</option>
          <option value={t("father")}>{t("father")}</option>
          <option value={t("mother")}>{t("mother")}</option>
          <option value={t("uncle")}>{t("uncle")}</option>
          <option value={t("aunt")}>{t("aunt")}</option>
          <option value={t("brother")}>{t("brother")}</option>
          <option value={t("sister")}>{t("sister")}</option>
          <option value={t("grandfather")}>{t("grandfather")}</option>
          <option value={t("grandmother")}>{t("grandmother")}</option>
        </select>
      </div>
      <div className="flex flex-col w-full">
        <label htmlFor="e_contact">
          {t("phone")} <span className="text-red-600">*</span>
        </label>

        <PhoneInput
          defaultCountry="EG"
          id="e_contact"
          name="e_contact"
          required={true}
          value={data.e_contact}
          onChange={(e) => handleCustomElementChange(e, "e_contact")}
        />
      </div>
      <div className="flex flex-col w-full">
        <label htmlFor="e_email">{t("email")}</label>
        <input
          type="email"
          className="form-textbox"
          id="e_email"
          name="e_email"
          value={data.e_email}
          onChange={handleChange}
        />
      </div>
    </div>
  );

  return content;
};

export default EmergencyInfo;
