import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const isEmailAvailable = (input) => {
  return !!input ? isValidEmail(input) : true;
};
